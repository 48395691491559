import PhoneNumber from 'awesome-phonenumber';
import moment from 'moment';
import { isEmpty } from './index';

export const required = (value) => {
  return value && value.length ? true : 'requiredFieldError';
};

export const fileRequired = (value) => {
  return value ? true : 'requiredFieldError';
};

export const ageValidator = (value) => {
  const today = new Date();
  const birthDate = new Date(moment(value, 'dd.mm.yyyy').toDate());
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  const oldEnough = age >= 18;

  return oldEnough ? true : 'errorTooYoung';
};

export const percentageValidator = (value) => {
  return Number(value) <= 100 && Number(value) > 0 ? true : 'errorPercentageInvalid';
};

export const phoneNumberValidator = (value) => {
  if (value === null) return true;
  const parsed = PhoneNumber(value);
  return parsed.g.valid ? true : 'errorInvalidPhone';
};

export const localPhoneNumberValidator = (value) => {
  if (value === null) return true;
  const parsed = PhoneNumber(value, 'DE');

  return parsed.g.valid ? true : 'errorInvalidPhone';
};

export const emailValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  // eslint-disable-next-line
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (Array.isArray(value)) {
    return value.every((val) => re.test(String(val)));
  }

  return re.test(String(value)) ? true : 'emailIsNotValid';
};

export const simplePasswordValidator = (password) => {
  /* eslint-disable no-useless-escape */
  const regExp = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password);

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ? true : 'errorPasswordTooShort'
  );
};
export const passwordValidator = (password) => {
  /* eslint-disable no-useless-escape */
  const regExp = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{14,}$/;
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password);

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ? true : 'errorPasswordTooShort'
  );
};

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'The Confirm Password field confirmation does not match';

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value);

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`;
};

export const integerValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every((val) => /^-?[0-9]+$/.test(String(val)));
  }

  return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer';
};

export const integerWithDecimalValidator = (value) => {
  if (isEmpty(value)) {
    return false;
  }

  if (Array.isArray(value)) {
    return value.every((val) => /^[0-9]+(,[0-9]{1,2})?/.test(String(val))) || 'This field must be a number';
  }

  return /^[0-9]+(,[0-9]{1,2})?/.test(String(value)) || 'This field must be a number';
};

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true;
  }

  let regeX = regex;
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX);
  }

  if (Array.isArray(value)) {
    return value.every((val) => regexValidator(val, { regeX }));
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid';
};

export const alphaValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters';
};

export const urlValidator = (value) => {
  if (value === undefined || value === null || value.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

  return re.test(value) || 'URL is invalid';
};

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true;
  }

  return value.length === length || `The Min Character field must be at least ${length} characters`;
};
export const alphaDashValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  const valueAsString = String(value);

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid';
};

export const numberRequired = (value) => {
  return String(value) ? true : 'requiredFieldError';
};

export const blankValidator = (value) => {
  return value && !!value.trim() ? true : 'requiredFieldError';
};
